import React, {
	ChangeEvent,
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useNavigate,
	useOutletContext,
	useParams,
} from 'react-router-dom';

// TYPES
import {
	Media,
} from '@@types/Media';

// ROUTES
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumMediaType,
} from '@enums/media.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

// EXCEPTIONS
import DownloadDocumentMediaError from '@exceptions/DownloadDocumentMediaError';

// MODULES
import {
	getURL,
} from '@modules/utils';
import {
	convertFileToBase64,
} from '@modules/images';

// CONFIG
import {
	deleteOfferMedia,
	patchOfferMedia,
	getOfferMedia,
	postOfferMedia,
} from '../config/fetch';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import ActionBar from '@components/action-bar';
import Button from '@components/button';
import {
	Dropzone,
	DropzoneChildDefault,
	DropzoneChildOver,
	DropzoneChildProcessing,
} from '@components/dropzone';
import Image from '@components/image';
import Link from '@components/link';
import {
	MediaCard,
} from '@components/media-card';

// ASSETS
import DropYourFileImage from '@assets/images/lpdipro/drop_your_file.png';

// STYLES
import styles from './media.module.scss';

const Media = () => {
	const {t} = useTranslation();
	const { offerid, mediatype } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : null;
	const navigate = useNavigate();

	const validationType = [
		'documents',
		'photos',
		'plans',
		'videos',
	];

	useEffect(() => {
		if (!validationType.includes(mediatype)) {
			navigate(getURL(PATHS.OFFERS.EDIT.MEDIA, {
				':offerid': `${currentOfferId}`,
				':mediatype': 'photos',
			}));
		}
	}, [
	]);

	const {
		isDesktopResolution,
		mediaOfferState,
		offerState,
		setMediaOfferState,
		setOfferState,
	}: PageOfferContextProps = useOutletContext();

	const [
		isProcessing,
		setIsProcessing,
	] =	useState(false);

	const [
		selectedMediaIds,
		setSelectedMediaIds,
	] = useState<number[]>([
	]);

	let mediaTypeAccepted = undefined;

	switch (mediatype) {
		case 'photos':
		case 'plans':
			mediaTypeAccepted = {
				'image/*': [
					'.png',
					'.jpg',
					'.jpeg'
				],
			};
			break;
		default:
			break;
	}

	const getFileType = (mediatype: string) => {
		switch (mediatype) {
			case 'photos':
				return EnumMediaType.PHOTO;
			case 'plans':
				return EnumMediaType.PLAN;
		}
	};

	const moveToCategory = (event: MouseEvent<HTMLElement>, updatedMedia:{
		type: EnumMediaType;
		ids: number[];
	}) => {
		event.preventDefault();
		const mediaMoveToPlans = mediaOfferState?.media?.filter((mediaData: Media) => !!updatedMedia.ids.includes(mediaData.id)).map((mediaData: Media) => {
			return {
				...mediaData,
				is_main: false,
				type: updatedMedia.type,
			};
		}) as Media[];
		patchOfferMedia(currentOfferId, mediaMoveToPlans).then(responseParsed => {
			if (responseParsed.status === 200) {
				getOfferMedia(currentOfferId).then(responseParsed => {
					if (responseParsed.status === 200) {
						setMediaOfferState(
							{
								...mediaOfferState,
								is_loaded: true,
								media: responseParsed.payload,
							}
						);
					}
				});
			}
		});
	};

	const handleDownloadMedia = async (event: MouseEvent<HTMLElement>, media: Media ) => {
		event.preventDefault();
		try {
			const response = await fetch(media.url);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.setAttribute('download', media.filename);
			link.style.display = 'none';
			link.href = url;
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			throw new DownloadDocumentMediaError(error);
		}
	};

	const handleDeleteMedia = (event: MouseEvent<HTMLElement>, mediaIds: number[]) => {
		event.preventDefault();
		deleteOfferMedia(currentOfferId, mediaIds).then(responseParsed => {
			if (responseParsed.status === 200) {
				getOfferMedia(currentOfferId).then(responseParsed => {
					if (responseParsed.status === 200) {
						setMediaOfferState(
							{
								...mediaOfferState,
								is_loaded: true,
								media: responseParsed.payload,
							}
						);
					}
				});
			}
		});
	};

	const dropzoneElement = (
		<Dropzone
			isProcessing={isProcessing}
			options={{
				accept: mediaTypeAccepted,
				maxSize: 10485760
			}}
			onDrop={(acceptedFiles) => {
				setIsProcessing(true);

				acceptedFiles.forEach(async (file: File) => {
					const formatedFile = await convertFileToBase64(file).then((convertedFile) => {
						return {
							filename: file.name,
							type: getFileType(mediatype),
							content: convertedFile,
						};
					});

					await postOfferMedia(currentOfferId, [
						formatedFile
					]).then(responseParsed => {
						if (responseParsed.status === 200) {
							getOfferMedia(currentOfferId).then(responseParsed => {
								if (responseParsed.status === 200) {
									setMediaOfferState(
										{
											...mediaOfferState,
											is_loaded: true,
											media: responseParsed.payload,
										}
									);
								}
							});
						}
					}).finally(() => {
						setIsProcessing(false);
					});
				});
			}}
		>
			<DropzoneChildDefault
				className={styles.dropzone}
				key='1'
			>
				<span
					className={[
						styles.line,
						styles.grey
					].join(' ')}
				>
					{t('page.offers_create_edit.media.image_weight_should_not_exceed')}
				</span>
				<span className={styles.line}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.drag_and_drop_your_photos')
					})}
				</span>
				<span
					className={[
						styles.line,
						styles.surroundedbydashes
					].join(' ')}
				>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.or')
					})}
				</span>
				<Button
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.select_your_files')
					})}
				/>
			</DropzoneChildDefault>
			<DropzoneChildOver
				className={[
					styles.dropzone,
					styles.active
				].join(' ')}
				key='2'
			>
				<Image
					alt={t('format.capitalize', {
						text: t('page.offers_create_edit.media.drop_your_files_here')
					})}
					className={styles.dropzone__image}
					src={DropYourFileImage}
				/>
				<span className={styles.line}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.media.drop_your_files_here')
					})}
				</span>
			</DropzoneChildOver>,
			<DropzoneChildProcessing
				className={[
					styles.dropzone,
					styles.isprocessing
				].join(' ')}
				key='4'
			>
				<div className={styles.line}>
					{t('format.capitalize', {
						text: t('general.loading')
					})}
				</div>
			</DropzoneChildProcessing>
		</Dropzone>
	);

	const mediaList = mediaOfferState?.media?.filter((mediaData: Media) => mediaData.type === getFileType(mediatype));

	const mediaCardList = mediaList?.length ? (
		<div className={styles.media_list}>
			{mediaList.map((mediaData: Media, index: number) => {
				return (
					<MediaCard
						className={styles.media_card}
						isChecked={selectedMediaIds.includes(mediaData.id)}
						key={index}
						labels={mediaData.is_main ? [
							{
								text: t('format.capitalize', {
									text: t('page.offers_create_edit.media.main_photo'),
								}),
								theme: EnumTheme.PRIMARY,
							}
						] : undefined}
						mediaData={mediaData}
						menuItems={[
							{
								label: t('format.capitalize', {
									text: t('page.offers_create_edit.media.set_as_main_photo'),
								}),
								iconLeft: 'one-circle',
								iconStyle: EnumFontStyle.LIGHT,
								href: '#',
								onClick: (event) => {
									event.preventDefault();
									patchOfferMedia(currentOfferId, [
										{
											...mediaData,
											type: mediaData.type,
											is_main: true,
										} as Media
									]).then(responseParsed => {
										if (responseParsed.status === 200) {
											getOfferMedia(currentOfferId).then(responseParsed => {
												if (responseParsed.status === 200) {
													setMediaOfferState(
														{
															...mediaOfferState,
															is_loaded: true,
															media: responseParsed.payload,
														}
													);
												}
											});
										}
									});
								}
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.move_to', {
										text: t(`page.offers_create_edit.media.${mediatype === 'photos' ? 'plans' : 'photos'}`),
									}),
								}),
								iconLeft: 'share-arrow',
								iconStyle: EnumFontStyle.LIGHT,
								href: '#',
								onClick: (event) => {
									moveToCategory(event, {
										type: getFileType(mediatype) === EnumMediaType.PHOTO ? EnumMediaType.PLAN : EnumMediaType.PHOTO,
										ids: [
											mediaData.id
										],
									});
								}
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.download'),
								}),
								iconLeft: 'arrow-to-bottom',
								iconStyle: EnumFontStyle.LIGHT,
								href: '#',
								onClick: (event) => {
									handleDownloadMedia(event, mediaData);
								},
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.edit'),
								}),
								iconLeft: 'edit',
								iconStyle: EnumFontStyle.LIGHT,
								href: '#',
								separator: true,
							},
							{
								label: t('format.capitalize', {
									text: t('general.action.delete'),
								}),
								iconLeft: 'trash-alt',
								iconStyle: EnumFontStyle.LIGHT,
								href: '#',
								onClick: (event) => {
									handleDeleteMedia(event, [
										mediaData.id
									]);
								}
							}
						]}
						onChange={(event: ChangeEvent<HTMLInputElement>) => {
							if (event.target.checked) {
								setSelectedMediaIds([
									...selectedMediaIds,
									mediaData.id
								]);
							} else {
								setSelectedMediaIds(
									selectedMediaIds.filter((mediaId: number) => mediaId !== mediaData.id)
								);
							}
						}}
					/>
				);
			})}
		</div>
	) : undefined;

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 5/8`
				})}
			</div>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.media.import_your_media')
				})}
			</div>
			<div className={styles.button_list}>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Default}
					counter={0}
					iconLeft={'picture'}
					iconStyle={EnumFontStyle.REGULAR}
					isActive={mediatype === 'photos'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.photos'),
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					to={getURL(PATHS.OFFERS.EDIT.MEDIA, {
						':offerid': `${currentOfferId}`,
						':mediatype': 'photos',
					})}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Default}
					counter={0}
					iconLeft={'plan'}
					iconStyle={EnumFontStyle.REGULAR}
					isActive={mediatype === 'plans'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.media.plans'),
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					to={getURL(PATHS.OFFERS.EDIT.MEDIA, {
						':offerid': `${currentOfferId}`,
						':mediatype': 'plans',
					})}
				/>
			</div>
			{dropzoneElement}
			{mediaCardList}
			<ValidationForm
				isDesktopResolution={isDesktopResolution}
				isValid={offerState?.addresses?.length ? true : false}
				offerState={offerState}
				setOfferState={setOfferState}
			/>
			{selectedMediaIds.length ? (
				<div className={styles.multi_actions__container}>
					<ActionBar className={styles.multi_actions}>
						<div className={styles.selected_counter}>
							<div>
								{t('format.capitalize', {
									text: t('component.table.action_bar_counter', {
										'%type%': 'photos',
									})
								})}&nbsp;
								<span className={styles.counter}>{selectedMediaIds.length}/{mediaList.length}</span>
							</div>
							<Link
								theme={EnumThemeLink.NAKED}
								onClick={(e) => {
									e.preventDefault();
									setSelectedMediaIds([
									]);
								}}
							>
								{t('general.action.cancel')}
							</Link>
						</div>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							iconLeft={'trash-alt'}
							iconStyle={EnumFontStyle.REGULAR}
							label={t('format.capitalize', {
								text: t('general.action.delete')
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={(event) => {
								handleDeleteMedia(event, selectedMediaIds);
							}}
						/>
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Default}
							iconLeft={'switch'}
							iconStyle={EnumFontStyle.REGULAR}
							label={t('format.capitalize', {
								text: t('general.action.move_to', {
									text: t(`page.offers_create_edit.media.${mediatype === 'photos' ? 'plans' : 'photos'}`),
								}),
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.PRIMARY}
							onClick={(event) => {
								moveToCategory(event, {
									type: getFileType(mediatype) === EnumMediaType.PHOTO ? EnumMediaType.PLAN : EnumMediaType.PHOTO,
									ids: selectedMediaIds,
								});
							}}
						/>
					</ActionBar>
				</div>
			) : undefined}
		</div>
	);
};

export default withTranslation()(Media);
