import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

// EXCEPTIONS
import GetOfferEnumsError from '@exceptions/GetOfferEnumsError';

// REDUCERS
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';
import {
	Media,
} from '@@types/Media';

// SLICES
import {
	DeviceDisplays,
} from '@stores/_slices/device';

// CONFIG
import PATHS from '@routes/paths';
import {
	getOffer,
	getOfferContacts,
	getOfferEnums,
	getOfferMedia,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageOfferEntry/header';

// STYLES
import styles from './PageOfferEntry.module.scss';

export interface AddressesProps {
	id: number;
	decoded: Record<string, unknown>;
	icon?: string;
	is_main: boolean;
	is_valid?: boolean;
	street_raw: string;
	pretty_street_raw?: string;
	type?: string;
}

export interface OfferStateProps {
	addresses?: AddressesProps[];
	building_name?: string;
	caz_name?: string;
	id?: number;
	infos?: Record<string, unknown>[] | Record<string, undefined>;
	is_private?: boolean;
	is_published?: boolean;
	leases?: {
		details?: {
			[key: string]: string | number;
		};
		rental_ref_lease_type_id?: number;
	}[];
	mandate_type?: string;
	program_name?: string;
	ref_building_commercial_zone_id?: string;
	ref_property_nature_main_id?: number;
	steps?: {
		[key: string]: boolean;
	};
	transport_access?: string;
	types?: string[];
	updated_steps?: {
		[key: string]: boolean;
	};
}
export interface OfferEnumsStateProps {
	contact_roles?: {
		key: string;
		value: number;
	}[];
	mandate_type?: string[];
	ref_building_commercial_zones?: string[];
	ref_property_nature_announcements: {
			key: string;
			value: number;
		}[];
	rental_ref_lease_types?: {
		key: string;
		value: number;
	}[];
	types?: string[];
	visibilities: {
		key: string;
		value: number;
	}[];
}

export interface OfferStateContactsProps {
	is_loaded: boolean;
	contacts: Collection<ContactOfferJson, ContactOffer>;
}

export interface MediaOfferProps {
	is_loaded: boolean;
	media?: Media[];
}

export interface PageOfferContextProps {
	isDesktopResolution: boolean;
	offerState?: OfferStateProps;
	offerStateContacts?: OfferStateContactsProps;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
	setOfferState: (Dispatch<SetStateAction<OfferStateProps>>);
	offerEnumsState: OfferEnumsStateProps;
	mediaOfferState: MediaOfferProps;
	setMediaOfferState: (Dispatch<SetStateAction<MediaOfferProps>>);
}

const PageOfferEntry = () => {
	const {offerid} = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP;

	const navigate = useNavigate();

	const contentEntryOfferRef = useRef(null);

	const location = useLocation();

	const [
		isLoaded,
		setIsLoaded,
	] = useState(false);

	const [
		offerState,
		setOfferState
	] = useState();

	const [
		offerStateContacts,
		setOfferStateContacts,
	] = useState({
		is_loaded: false,
		contacts: undefined,
	});

	const [
		mediaOfferState,
		setMediaOfferState
	] = useState({
		is_loaded: false,
		media: undefined
	});

	const [
		offerEnumsState,
		setOfferEnumsState,
	] = useState(undefined);

	useEffect(() => {
		// Async function to handle data fetching
		const getOfferData = async () => {
			try {
				const enumsResponse = await getOfferEnums();
				handleErrorResponse(enumsResponse, GetOfferEnumsError);
				if (enumsResponse.status === 200) {
					setOfferEnumsState(enumsResponse.payload);
				}

				// Check if it's a new offer edit route
				if (routesMapping.some(({ route }) => matchRoutes(route, location.pathname)?.length)) {
					// Fetch enums for editing an existing offer
					const enumsResponse = await getOfferEnums();
					handleErrorResponse(enumsResponse, GetOfferEnumsError);
					if (enumsResponse.status === 200) {
						setOfferEnumsState(enumsResponse.payload);
					}

					// Fetch offer details
					const offerResponse = await getOffer(currentOfferId);
					if (offerResponse.status === 200) {
						setOfferState({
							...offerResponse.payload,
							id: currentOfferId,
						});
						setIsLoaded(true);

						// Fetch offer contacts
						const contactsResponse = await getOfferContacts(currentOfferId);
						if (contactsResponse.status === 200) {
							setOfferStateContacts({
								contacts: contactsResponse.payload,
								is_loaded: true,
							});
						}
					} else {
					// Navigate to the homepage if the offer is not found
						navigate(PATHS.HOMEPAGE);
						return;
					}

					// Fetch offer media
					const mediaResponse = await getOfferMedia(currentOfferId);
					if (mediaResponse.status === 200) {
						setMediaOfferState({
							...mediaOfferState,
							is_loaded: true,
							media: mediaResponse.payload,
						});
					}
				}
			} catch (error) {
				// Handle any error by navigating to the homepage
				navigate(PATHS.HOMEPAGE);
			} finally {
				setIsLoaded(true); // Mark as loaded when enums are fetched
				// Ensure contacts are marked as loaded even in case of error
				setOfferStateContacts((prevContacts) => ({
					...prevContacts,
					is_loaded: true,
				}));
			}
		};

		// Call the async function when the component mounts
		getOfferData();
	}, [
	]);

	return isLoaded ? (
		<Page className={styles.PageOfferEntry}>
			<div
				className={styles.PageOfferEntry__content}
				ref={contentEntryOfferRef}
			>
				<Header
					contentEntryOfferRef={contentEntryOfferRef}
					isDesktopResolution={isDesktopResolution}
					offerState={offerState}
				/>
				<Outlet
					context={{
						isDesktopResolution,
						mediaOfferState,
						offerEnumsState,
						offerState,
						offerStateContacts,
						setMediaOfferState,
						setOfferState,
						setOfferStateContacts,
					}}
				/>
			</div>
		</Page>
	) : undefined;
};

export default withTranslation()(PageOfferEntry);
