import React, {
	Ref,
	useRef,
	useState,
} from 'react';
import Select, {
	ClearIndicatorProps,
	components,
	DropdownIndicatorProps,
	GroupBase,
	MultiValueProps,
	NoticeProps,
	SingleValueProps,
} from 'react-select';

import {
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
} from 'react-hook-form';

import {
	useTranslation,
} from 'react-i18next';
import formUtils from '@modules/formUtils';

import {
	ImageJson,
} from '@@types/Image';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFormMode,
} from '@enums/form.enum';

// COMPONENTS
import {
	InputProps,
	InputPropsCommon,
} from '@components/form/input';
import InputLabel from '@components/form/input-label';
import Icon from '@components/icon';

// STYLES
import styles from './input-multi-select.module.scss';

interface OptionType<T = unknown> {
	value: T;
	label: string | JSX.Element;
	inputValue: {
		label: string;
		img?: ImageJson;
	};
}

interface MethodsProps {
	register?: (name: string, rules: object) => InputMultiSelectProps;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	setError?: (name?: string) => void;
	clearErrors?: (name?: string) => void;
}

interface InputMultiSelectProps extends Omit<InputPropsCommon, 'defaultValue'> {
	className?: string;
	clearInputValue?: boolean;
	closeMenuOnSelect?: boolean;
	defaultValue?: OptionType;
	hasShadowMenu?: boolean;
	innerRef?: Ref<HTMLInputElement>;
	isDisabled?: boolean;
	isMulti?: boolean;
	maxMenuHeight?: number;
	menuIsOpen?: boolean;
	methods?: MethodsProps;
	noOptionsMessage?: string | (() => JSX.Element);
	onChange?: (selected: OptionType | OptionType[]) => void;
	onInputChange?: (inputValue: string) => void;
	options?: OptionType[];
	required?: boolean;
	rules?: object;
}

const InputMultiSelect = ({
	className,
	clearInputValue = true,
	closeMenuOnSelect,
	customError,
	'data-testid': dataTestid,
	defaultValue,
	hasShadowMenu = false,
	id,
	innerRef,
	isDisabled,
	isMulti = true,
	label,
	maxMenuHeight,
	menuIsOpen,
	methods,
	name,
	noOptionsMessage,
	onChange,
	onInputChange,
	options,
	pattern,
	placeholder,
	required,
	rules,
	type,
	validationType,
}: InputMultiSelectProps): JSX.Element => {
	const { t } = useTranslation();
	let register = null;
	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];

	const hookRef = useRef<HTMLDivElement>(null);
	const localRef = innerRef || hookRef;

	const [
		isInvalid,
		setIsInvalid
	] = useState(false);

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: customError ? customError : t('general.form.input.error.default')
			};
			registerRules.pattern = {
				value: pattern || formUtils.rulesByType['default'],
				message: customError ? customError : t(`general.form.input.error.${type}`)
			};
		}

		register = methods.register(name, registerRules);
	}

	const [
		selectedOptions,
		setSelectedOptions
	] = useState<OptionType | OptionType[]>(
		isMulti ? defaultValue || [
		] : defaultValue
	);

	const [
		inputValue,
		setInputValue
	] = useState('');

	const classes = [
		styles.react_select_container
	];

	if (className) classes.push(className);
	if (hasShadowMenu) classes.push(styles.has_shadow_menu);
	if (isDisabled) classes.push(styles.is_disabled);
	if ((methods?.formState?.errors[name]) || isInvalid) classes.push(styles.invalid);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const handleOnChange = (selected: OptionType | OptionType[], field: ControllerRenderProps) => {
		const newSelection = isMulti ? (selected as OptionType[]) : (selected as OptionType);
		setSelectedOptions(newSelection);
		setInputValue('');

		if (field) {
			field.onChange(newSelection);
		}

		if (onChange) {
			onChange(newSelection);
		}

		setIsInvalid(false);
	};

	const handleInputChange = (newValue: string, actionMeta: { action: string }) => {
		if (clearInputValue) {
			setInputValue(newValue);
		} else {
			if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
				setInputValue(newValue);
			}
		}
		/* istanbul ignore next */
		if (onInputChange) {
			onInputChange(newValue);
		}
	};

	const handleBlur = (field: ControllerRenderProps) => {
		/* istanbul ignore next */
		if (field && inputValue.trim() !== '') {
			field.onChange(inputValue);
			setSelectedOptions({
				label: inputValue,
				value: inputValue,
				inputValue: {
					label: inputValue,
				}
			});
		}
		const isEmptySelection = !selectedOptions || (Array.isArray(selectedOptions) && selectedOptions.length === 0);
		const shouldValidate = validationType !== EnumFormMode.ON_SUBMIT && required;
		const isInvalidInput = isEmptySelection && !inputValue;

		setIsInvalid(shouldValidate && isInvalidInput);
	};

	const labelElement = label ? (
		<InputLabel
			data-testid={`${dataTestid}-label`}
			id={id}
			textEllipsis={true}
		>
			{decodeURI(t(label))}
		</InputLabel>
	) : null;

	const CustomNoOptionsMessage = (props: NoticeProps<unknown, boolean, GroupBase<unknown>>) => {
		return (
			<components.NoOptionsMessage
				{...props}
			>
				{typeof noOptionsMessage === 'function'
					? noOptionsMessage()
					: noOptionsMessage || t('general.filter.no_result_filter.title')
				}
			</components.NoOptionsMessage>
		);
	};

	const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, true>) => {
		return (
			<components.DropdownIndicator {...props}>
				<Icon
					data-testid={`${dataTestid}-dropdown-indicator`}
					name={'chevron-down'}
				/>
			</components.DropdownIndicator>
		);
	};

	const CustomClearIndicator = (props: ClearIndicatorProps<OptionType, true>) => {
		return (
			<components.ClearIndicator {...props}>
				<Icon
					data-testid={`${dataTestid}-clear-indicator`}
					name={'times'}
				/>
			</components.ClearIndicator>
		);
	};

	const errorMsgElement = (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{customError ? customError : t('general.form.input.error.default')}
		</div>
	);

	const MultiValueLabel = (props: MultiValueProps<OptionType>) => {
		return (
			<components.MultiValueLabel {...props}>
				<div className={styles.label_custom}>
					{props.data.inputValue.img?.url ? (
						<img
							alt={props.data.inputValue.img.id}
							className={styles.image}
							src={props.data.inputValue.img.url}
						/>
					) : undefined} {props.data.inputValue.label}
				</div>
			</components.MultiValueLabel>
		);
	};

	const SingleValueLabel = (props: SingleValueProps<OptionType>) => {
		return (
			<components.SingleValue {...props}>
				<div className={styles.label_custom}>
					{props.data.inputValue.img?.url ? (
						<img
							alt={props.data.inputValue.img.id}
							className={styles.image}
							src={props.data.inputValue.img.url}
						/>
					) : undefined} {props.data.inputValue.label}
				</div>
			</components.SingleValue>
		);
	};

	const InPutMultiSelectComponent = (field?: ControllerRenderProps, fieldState?: ControllerFieldState) => (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
			ref={field ? field.ref : localRef}
		>
			{labelElement}
			<Select
				classNamePrefix="react-select"
				closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : isMulti ? false : true}
				components={{
					DropdownIndicator,
					ClearIndicator: CustomClearIndicator,
					SingleValue: isMulti ? undefined : SingleValueLabel,
					MultiValueLabel: isMulti ? MultiValueLabel : undefined,
					NoOptionsMessage: CustomNoOptionsMessage,
				}}
				defaultValue={defaultValue}
				formatOptionLabel={(option: { label: string }) => option.label}
				getOptionValue={(option: { inputValue: { label: string } }) => option.inputValue.label}
				inputValue={inputValue}
				isClearable={true}
				isDisabled={isDisabled}
				isMulti={isMulti}
				maxMenuHeight={maxMenuHeight}
				menuIsOpen={menuIsOpen}
				options={options}
				placeholder={placeholder}
				required={required}
				unstyled
				value={selectedOptions || inputValue}
				onBlur={() => handleBlur(field)}
				/* istanbul ignore next */
				onChange={(selected) => handleOnChange(selected as OptionType | OptionType[], field)}
				onInputChange={handleInputChange}
			/>
			{fieldState?.error || isInvalid ? errorMsgElement : null}
		</div>
	);

	return register ? (
		<Controller
			defaultValue={inputValue || defaultValue}
			name={name}
			render={({ field, fieldState }) => InPutMultiSelectComponent(field, fieldState)}
			rules={rules}
		/>
	) : InPutMultiSelectComponent();
};

InputMultiSelect.displayName = EnumComponentType.INPUT_MULTI_SELECT;

export {
	InputMultiSelect as default,
	OptionType,
};
