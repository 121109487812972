import React, {
	Dispatch,
	RefObject,
	SetStateAction,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	matchRoutes,
	useLocation,
	useNavigate,
} from 'react-router-dom';

// ROUTES
import PATHS from '@routes/paths';
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
} from '@routes/lpdipro/private/offer-entry';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// EXCEPTIONS
import PostOfferError from '@exceptions/PostOfferError';
import PutOfferError from '@exceptions/PutOfferError';
import GetOfferError from '@exceptions/GetOfferError';

// MODULES
import {
	getURL,
	handleErrorResponse,
} from '@modules/utils';

// CONFIG
import {
	postOffer,
	putOffer,
	getOffer,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// LAYOUTS
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

// COMPONENTS
import Form from '@components/form';
import Button from '@components/button';

// STYLES
import styles from './validation-form.module.scss';

interface ValidationFormProps {
	offerState: OfferStateProps;
	isDesktopResolution: boolean;
	inputs?: JSX.Element;
	isValid: boolean;
	formRef?: RefObject<HTMLFormElement>;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
	setOfferState: Dispatch<SetStateAction<OfferStateProps>>;
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

const ValidationForm = ({
	offerState,
	isDesktopResolution,
	inputs,
	isValid,
	formRef,
	setIsOpen,
	setOfferState,
}: ValidationFormProps): JSX.Element => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const location = useLocation();

	const onSubmitSuccess = async () => {
		if (!isValid) {
			if (setIsOpen) setIsOpen(true);
			setTimeout(() => {
				const submitEvent = new Event('submit', {
					bubbles: true,
					cancelable: true
				});
				formRef?.current?.dispatchEvent(submitEvent);
			}, 0);
			return;
		}

		try {
			let offerId = offerState.id;

			// If we are creating a new offer
			if (matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length) {
				const responseParsed = await postOffer(offerState);
				handleErrorResponse(responseParsed, PostOfferError);

				offerId = responseParsed.payload.id;

				// Navigate to the next step after creating the offer
				const goToOfferInfos = getURL(PATHS.OFFERS.EDIT.INFOS, {
					':offerid': `${offerId}`
				});
				const offerData = await getOffer(offerId);
				handleErrorResponse(offerData, GetOfferError);

				// Update the offer state with new data
				setOfferState({
					id: offerId,
					...offerData.payload,
				});

				navigate(goToOfferInfos);
			} else {
				// If editing an existing offer
				const responseParsed = await putOffer(offerState);
				handleErrorResponse(responseParsed, PutOfferError);

				// Update the current steps based on the current path
				const updatedSteps = updateSteps(offerState, location.pathname);
				setOfferState({
					...offerState,
					id: offerId,
					steps: updatedSteps
				});

				// Find the next route and navigate if available
				const nextRoute = getNextRoute(updatedSteps, location.pathname, offerId);

				if (nextRoute) {
					navigate(nextRoute);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	// Update the steps based on the current path and the offer's state
	const updateSteps = (offerState: OfferStateProps, currentPath: string): Record<string, boolean> => {
		const steps = {
			...offerState.steps
		};

		// If the offer is published, mark all steps as complete
		if (offerState.is_published) {
			routesMapping.forEach(({ step }) => (steps[step] = true));
		} else {
			// Otherwise, update only the current step
			routesMapping.forEach(({ step, route }) => {
				if (matchRoutes(route, currentPath)?.length) {
					steps[step] = true;
				}
			});
		}

		return steps;
	};

	// Function to get the next route based on the current step
	const getNextRoute = (
		steps: Record<string, boolean>,
		currentPath: string,
		offerId: number
	): string | null => {

		const currentRouteIndex = routesMapping.findIndex(({ route }) =>
			matchRoutes(route, currentPath)?.length
		);

		if (currentRouteIndex !== -1 && !offerState.is_published) {
			const nextRoute = routesMapping[currentRouteIndex + 1];
			return nextRoute ? getURL(nextRoute.route[0].path, {
				':offerid': `${offerId}`
			})	: undefined;
		}

		return undefined;
	};

	return (
		<Form
			className={styles.form}
			onSuccess={onSubmitSuccess}
		>
			<>{inputs}</>
			<div className={styles.button_group}>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.cancel')
					})}
					size={isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					type={EnumButtonType.BUTTON}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.continue')
					})}
					size={isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL}
					type={EnumButtonType.SUBMIT}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(ValidationForm);
