import React, {
	ChangeEvent,
	MouseEvent,
	useState,
} from 'react';
import {
	useTranslation, withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';

// COMPONENTS
import Image from '@components/image';
import InputCheckbox from '@components/form/input-checkbox';
import InputRadioGroup from '@components/form/input-radio-group';
import InputSelect from '@components/form/input-select';
import InputText from '@components/form/input-text';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Textarea from '@components/form/textarea';

// ASSETS
import infosImage from '@assets/images/lpdipro/illu_man_working.png';

// STYLES
import styles from './infos.module.scss';

const Infos = () => {
	const { t } = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
		offerEnumsState,
	} : PageOfferContextProps = useOutletContext();

	const defaultLease = offerEnumsState?.rental_ref_lease_types.find(item => item.value === offerState?.leases[0]?.rental_ref_lease_type_id)?.key;

	console.log('defaultLease', defaultLease);

	const [
		selectedLease,
		setSelectedLease
	] = useState(defaultLease);

	// A GARDER
	console.log('offerState', offerState);
	console.log('offerEnumsState', offerEnumsState);

	const natures = offerEnumsState?.ref_property_nature_announcements?.map((nature, index) => {
		return {
			id: `nature${index + 1}`,
			label: t('format.capitalize', {
				text: t(`references.natures.${nature.key}`)
			}),
			name: t('format.capitalize', {
				text: t(`references.natures.${nature.key}`)
			}),
			value: `${nature.value}`,
		};
	});

	const leases = offerEnumsState?.rental_ref_lease_types.map((lease_type, index) => {
		return {
			id: `lease${index + 1}`,
			label: t('format.capitalize', {
				text: t(`page.offers_create_edit.infos.lease.options.${lease_type.key}`)
			}),
			name: t('format.capitalize', {
				text: t(`page.offers_create_edit.infos.lease.options.${lease_type.key}`)
			}),
			value: `${lease_type.value}`,
		};
	});

	const refBuildingCommercialZones = offerEnumsState?.ref_building_commercial_zones.map((zone, index) => {
		return {
			id: `zone${index + 1}`,
			label: t('format.capitalize', {
				text: t(`references.announcement.${zone}`)
			}),
			name: t('format.capitalize', {
				text: t(`references.announcement.${zone}`)
			}),
			value: `${zone}`,
		};
	});

	const onLeaseChange = (selectedValue: number) => {
		const foundLease = offerEnumsState?.rental_ref_lease_types.find(item => item.value === selectedValue);
		const key = foundLease ? foundLease.key : null;
		setSelectedLease(key);
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = String(target.value);
		const name = target.name;

		const leases = [
		] as {
			details: {
				[key: string]: string | number;
			};
		}[];

		offerState.leases.forEach((lease) => {
			let details = undefined;
			details = {
				...lease,
				details: {
					...lease.details,
					[name]: value,
				}
			};

			leases.push(details);

			const updatedSteps = offerState?.updated_steps?.length ? {
				...offerState?.updated_steps,
				infos: true,
			} : {
				infos: true,
			};
			setOfferState({
				...offerState,
				leases: leases,
				steps: {
					...offerState?.steps,
					infos: false,
				},
				updated_steps: updatedSteps,
			});
		});
	};

	const inputsInfo = () => {
		switch (selectedLease) {
			case 'commercial': {
				return (
					<>
						{Array.from({
							length: 3
						}, (_, index) => (
							<InputTextWithUnit
								defaultValue={
									offerState?.leases[0]?.details?.[`nb_years_${index + 1}`]
										? (offerState.leases[0].details[`nb_years_${index + 1}`] as string)
										: undefined
								}
								key={index}
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.lease.commercial.nb_years.label')
								})}
								name={`nb_years_${index + 1}`}
								type={EnumInputType.NUMBER}
								unit={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.lease.commercial.nb_years.unit')
								})}
								onChange={handleOnChange}
							/>
						))}
						<InputText
							defaultValue={offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity as string : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.label')
							})}
							name="additional_maturity"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.placeholder')
							})}
							onChange={handleOnChange}
						/>
					</>
				);
			}
			case 'lease_to_be_specified':
			case 'professional':
			case 'tenancy_overriding':
			case 'subletting':
			case 'service_agreement': {
				return (
					<>
						<InputText
							defaultValue={offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity as string : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.label')
							})}
							name="additional_maturity"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.placeholder')
							})}
							onChange={handleOnChange}
						/>
					</>
				);
			}
			default:
				return null;
		}
	};

	const privatePublicOptions = offerEnumsState?.visibilities.map(({ key, value }) => {
		return {
			id: key,
			label: t('format.capitalize', {
				text: t(`page.offers_create_edit.infos.visibilities.${key}`)
			}),
			name: t('format.capitalize', {
				text: t(`page.offers_create_edit.infos.visibilities.${key}`)
			}),
			value: value,
		};
	});

	const mandateType = offerEnumsState?.mandate_type.map((value: string) => {
		return {
			id: value,
			label: t('format.capitalize', {
				text: t(`general.mandate.${value}`)
			}),
			name: t('format.capitalize', {
				text: t(`general.mandate.${value}`)
			}),
			value: value,
		};
	});

	const inputsInfos = (
		<>
			{/* --------- Is this offer private or public? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.visibilities.title')
				})}
			</div>
			<InputRadioGroup
				name="type_visibility"
				options={privatePublicOptions}
				selectedIndex={offerState.is_private ? 1 : 0}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const target = event.target as HTMLInputElement;
					const value = target.value === 'true';
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						is_private: value,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>

			{/* --------- What is the main nature of your offer? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.nature.title')
				})}
			</div>
			<InputSelect
				className={styles.full_select}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.nature.label')
				})}
				name="nature"
				options={natures}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.nature.placeholder')
				})}
				selectedIndex={offerEnumsState?.ref_property_nature_announcements.findIndex((option) => option.value === offerState.ref_property_nature_main_id) || 0}
				onClickOption={(event: MouseEvent<HTMLElement>) => {
					const target = event.target as HTMLElement;
					const selectElement = Number(target.closest('form').querySelector('select').value);
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						ref_property_nature_main_id: selectElement,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>

			{/* --------- What is the name of the building? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building.title')
				})}
			</div>
			<InputText
				defaultValue={offerState.building_name ? offerState.building_name : undefined}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building.label')
				})}
				name="building"
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building.placeholder')
				})}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const target = event.target as HTMLInputElement;
					const value = target.value;
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						building_name: value,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>

			{/* --------- Information related to the building --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building_info.title')
				})}
			</div>
			<div className={styles.form_row}>
				<InputText
					defaultValue={offerState.program_name ? offerState.program_name : undefined}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.infos.building_info.program_name.label')
					})}
					name="program_name"
					placeholder={t('format.capitalize', {
						text: t('page.offers_create_edit.infos.building_info.program_name.placeholder')
					})}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						const target = event.target as HTMLInputElement;
						const value = target.value;
						const updatedSteps = offerState?.updated_steps?.length ? {
							...offerState?.updated_steps,
							infos: true,
						} : {
							infos: true,
						};
						setOfferState({
							...offerState,
							program_name: value,
							steps: {
								...offerState?.steps,
								infos: false,
							},
							updated_steps: updatedSteps,
						});
					}}
				/>
				<InputText
					defaultValue={offerState.caz_name ? offerState.caz_name : undefined}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.infos.building_info.caz_name.label')
					})}
					name="caz_name"
					placeholder={t('format.capitalize', {
						text: t('page.offers_create_edit.infos.building_info.caz_name.placeholder')
					})}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						const target = event.target as HTMLInputElement;
						const value = target.value;
						const updatedSteps = offerState?.updated_steps?.length ? {
							...offerState?.updated_steps,
							infos: true,
						} : {
							infos: true,
						};
						setOfferState({
							...offerState,
							caz_name: value,
							steps: {
								...offerState?.steps,
								infos: false,
							},
							updated_steps: updatedSteps,
						});
					}}
				/>
			</div>
			<InputSelect
				className={styles.full_select}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building_info.ref_building_commercial_zones.label')
				})}
				name="ref_building_commercial_zones"
				options={refBuildingCommercialZones}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.building_info.ref_building_commercial_zones.placeholder')
				})}
				selectedIndex={refBuildingCommercialZones.findIndex(
					(option) => option.value === offerState?.ref_building_commercial_zone_id
				)}
				onClickOption={(event: MouseEvent<HTMLElement>) => {
					const target = event.target as HTMLElement;
					const selectValue = target.getAttribute('data-select-value');
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						ref_building_commercial_zone_id: selectValue,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>

			{/* --------- What are the types of transport around your offer? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.transport_access.title')
				})}
			</div>
			<Textarea
				defaultValue={offerState.transport_access ? offerState.transport_access : undefined}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.transport_access.label')
				})}
				name="transport"
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.transport_access.placeholder')
				})}
				rows={5}
				onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
					const target = event.target as HTMLTextAreaElement;
					const value = target.value;
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						transport_access: value,
						updated_steps: updatedSteps,
					});
				}}
			></Textarea>

			{/* --------- What is your type of mandate? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.mandate_type.title')
				})}
			</div>
			<InputRadioGroup
				name="mandate_type"
				options={mandateType}
				selectedIndex={offerEnumsState?.mandate_type.findIndex((option: string) => option === offerState.mandate_type)}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const target = event.target as HTMLInputElement;
					const value = target.value;
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						mandate_type: value,
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>

			{/* --------- What is your type of offer? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.types.title')
				})} {<span>*</span>}
			</div>
			<div className={styles.checkbox}>
				{offerEnumsState?.types.map((type, key) => {
					return (
						<InputCheckbox
							checked={offerState.types?.includes(type)}
							id={`offer_type_${type}`}
							key={key}
							label={t('format.capitalize', {
								text: t(`page.offers_create_edit.infos.types.${type}`)
							})}
							name="offerType[]"
							required={true}
							value={type}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								const value = target.value;
								const isChecked = target.checked;

								let types = offerState.types || [
								] as string[];
								if (isChecked) {
									types.push(value);
								} else {
									types = types.filter((type) => type !== value);
								}
								const updatedSteps = offerState?.updated_steps?.length ? {
									...offerState?.updated_steps,
									infos: true,
								} : {
									infos: true,
								};
								setOfferState({
									...offerState,
									steps: {
										...offerState?.steps,
										infos: false,
									},
									types: types,
									updated_steps: updatedSteps,
								});
							}}
						/>
					);
				})}
			</div>

			{/* --------- What is your type of lease? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.infos.lease.title')
				})}
			</div>
			<InputSelect
				className={styles.full_select}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.lease.label')
				})}
				name="lease"
				options={leases}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.infos.lease.placeholder')
				})}
				selectedIndex={offerState?.leases && offerState.leases.length > 0
					? offerEnumsState?.rental_ref_lease_types.findIndex((lease) => lease.value === offerState?.leases[0]?.rental_ref_lease_type_id) : undefined}
				style={{
					bottom: 'calc(100% + 6px)',
					left: '0'
				}}
				onClickOption={(event: MouseEvent<HTMLElement>) => {
					const target = event.target as HTMLElement;
					const selectValue = Number(target.getAttribute('data-select-value'));
					onLeaseChange(selectValue);
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						infos: true,
					} : {
						infos: true,
					};
					setOfferState({
						...offerState,
						leases: [
							{
								rental_ref_lease_type_id: selectValue,
								details: {
									additional_maturity: offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity : undefined,
								}
							}
						],
						steps: {
							...offerState?.steps,
							infos: false,
						},
						updated_steps: updatedSteps,
					});
				}}
			/>
			<div className={styles.form_column}>
				{inputsInfo()}
			</div>
		</>
	);

	return (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 2/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				<ValidationForm
					inputs={inputsInfos}
					isDesktopResolution={isDesktopResolution}
					isValid={offerState.types?.length ? true : false}
					offerState={offerState}
					setOfferState={setOfferState}
				/>
			</div>
			{isDesktopResolution && (
				<div className={styles.section__right}>
					<Image
						alt='placeholder'
						className={styles.image}
						src={infosImage}
					/>
				</div>
			)}
		</div>
	);
};

export default withTranslation()(Infos);
