import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	OfferStateContactsProps,
} from '@layouts/Pages/PageOfferEntry/index';

// CONFIG
import {
	getOfferContacts,
	getSearchContacts,
	postOfferContacts,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	handleErrorResponse,
} from '@modules/utils';
import GetOfferContactsError from '@exceptions/GetOfferContactsError';
import PostOfferContactError from '@exceptions/PostOfferContactError';
// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// TYPES
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';
import {
	Collection,
} from '@@types/Collection';

// ENUMS
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// COMPONENTS
import Button from '@components/button';
import Icon from '@components/icon';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import TooltipModal from '@components/tooltip-modal';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './contact-row.module.scss';

interface ContactRowProps {
	canSelect?: boolean;
	checkboxElement?: ReactNode;
	className?: string;
	currentOfferId: number;
	isDesktopResolution: boolean;
	isSelected?: boolean;
	offerContact: ContactOffer;
	offerStateContacts: OfferStateContactsProps;
	onClickRow?: (rowId: number) => void;
	rowId?: number;
	selectedRowId?: number;
	setIsOpenModal: Dispatch<SetStateAction<boolean>>;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

const ContactRow = ({
	canSelect,
	checkboxElement,
	className,
	currentOfferId,
	isDesktopResolution,
	isSelected,
	offerContact,
	offerStateContacts,
	onClickRow,
	rowId,
	selectedRowId,
	setIsOpenModal,
	setOfferStateContacts,
}: ContactRowProps): JSX.Element => {
	const { t } = useTranslation();

	console.log('isDesktopResolution', isDesktopResolution);

	const cssClasses = [
		styles.contact_row
	];

	const [
		activeElement,
		setActiveElement
	] = useState<string | null>(null);

	const [
		searchContacts,
		setSearchContacts
	] = useState([
	] as ContactOffer[]);

	const [
		query,
		setQuery
	] = useState(undefined);

	const [
		inputValue,
		setInputValue
	] = useState<string>(undefined);

	const [
		isTooltipOpen,
		setIsTooltipOpen
	] = useState(false);

	useEffect(() => {
		const fetchSearchContacts = async () => {
			return await getSearchContacts(query).then(responseParsed => {
				if (responseParsed.status === 200) {
					if (offerStateContacts?.contacts?.collection?.length > 0) {
						const existingContactIds = offerStateContacts.contacts.collection.map(contact => contact.id);
						const filteredContacts = responseParsed.payload.collection.filter((contact: ContactOffer) => {
							return !existingContactIds.includes(contact.id);
						});
						setSearchContacts(filteredContacts);
					} else {
						setSearchContacts(responseParsed.payload.collection);
					}
				}
			});
		};

		fetchSearchContacts();
	}, [
		query
	]);

	useDebouncedEffect(() => {
		if (inputValue?.length >= 3) {
			setQuery(inputValue);
		} else {
			setQuery(undefined);
		}
	}, [
		inputValue
	], APP_CONF_VARS.timeout.debounce);

	const searchOptions = searchContacts.length > 0 ? searchContacts.map((searchContact) => {
		const newSearchContact = new ContactOffer(searchContact);
		const userCardDataSearch = {
			title: newSearchContact?.full_name,
			id: newSearchContact?.id?.toString(),
			image_url: newSearchContact.logo?.url,
			descriptions: [
				{
					value: newSearchContact?.company_name,
					type: 'subtitle'
				},
			],
		};

		return {
			value: newSearchContact,
			inputValue: {
				label: newSearchContact.full_name
					? `${newSearchContact.full_name}${newSearchContact.company_name ? ` - ${newSearchContact.company_name}` : ''}`
					: newSearchContact.company_name,
				img: newSearchContact.logo,
			},
			label: newSearchContact.is_default ? (
				<div className={styles.mandat_pending}>
					<span>?</span>
					{t('format.capitalize', {
						text: t('general.awaiting_mandate')
					})}
				</div>
			) : (
				<UserCard
					data={userCardDataSearch}
					theme={ThemeUserCard.SQUARE}
				/>
			),
		};
	}) : [
	];

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.contact_row__can_select);
	if (isSelected) cssClasses.push(styles.contact_row__selected);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (onClickRow) onClickRow(offerContact?.id);
		const id = event.currentTarget.id;
		setActiveElement(prev => (prev === id ? null : id));
	};

	const userCardData = {
		title: offerContact?.full_name,
		id: offerContact?.id?.toString(),
		image_url: offerContact?.logo?.url,
		descriptions: [
			{
				value: offerContact?.company_name,
				type: 'subtitle'
			},
		],
	};

	const inputSearchElement = (
		<div className={styles.input_search_container}>
			<InputMultiSelect
				defaultValue={offerContact ?
					{
						label: offerContact.full_name,
						value: offerContact,
						inputValue: {
							label: offerContact.full_name,
							img: offerContact.logo,
						},
					} : undefined
				}
				isMulti={false}
				maxMenuHeight={220}
				menuIsOpen={true}
				name={'name'}
				noOptionsMessage={() => (
					<div className={styles.no_options_message}>
						<Icon
							className={styles.icon}
							fontStyle={EnumFontStyle.LIGHT}
							name='search-slash'
						/>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.no_results_message')
						})}
					</div>
				)}
				options={searchOptions}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.add_contact')
				})}
				onChange={(selected: OptionType | OptionType[]) => {
					setIsTooltipOpen(false);
					const selectedOption = Array.isArray(selected) ? selected[0]?.value as ContactOffer : selected?.value as ContactOffer;

					const newContact = {
						firstname: selectedOption?.firstname,
						lastname: selectedOption?.lastname,
						company_name: selectedOption?.company_name,
						logo: selectedOption?.logo,
						id: selectedOption?.id,
						is_default: selectedOption?.is_default,
					};

					let contactIsReplaced = false;
					const contactList = Array.isArray(offerStateContacts?.contacts?.collection)
						? offerStateContacts.contacts.collection.map((contact: ContactOffer, index: number) => {
							if (index === rowId) {
								contactIsReplaced = true;
								return newContact;
							}
							return contact;
						})
						: [
						];

					const newContactList = contactIsReplaced ? contactList : [
						...contactList,
						newContact,
					];

					postOfferContacts(currentOfferId, {
						contact_id: newContact.id,
					}).then(postOfferContactsResponse => {
						handleErrorResponse(postOfferContactsResponse, PostOfferContactError);
					});

					getOfferContacts(currentOfferId).then(getOfferContactsResponse => {
						handleErrorResponse(getOfferContactsResponse, GetOfferContactsError);
					});

					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: newContactList,
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}}
				onInputChange={(newValue: string) => {
					setInputValue(newValue);
					if (newValue === '') {
						setQuery(undefined);
					}
				}}
			/>
			<div className={styles.button_container}>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft={'plus'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.add_contact')
					})}
					theme={EnumTheme.NAKED}
					onClick={() => {
						setIsTooltipOpen(false);
						setIsOpenModal(true);
					}}
				/>
			</div>
		</div>
	);

	const contactIdentityElement = (
		<div
			className={`${styles.cell_identity} ${activeElement === 'identity' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="identity"
			onClick={handleOnClick}
		>
			<TooltipModal
				className={styles.tooltip_modal}
				clickable={true}
				htmlElement={inputSearchElement}
				id={'test'}
				isOpen={isTooltipOpen}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM_START}
				setIsOpen={setIsTooltipOpen}
			>
				<span className={styles.tooltip_modal_content}>
					{
						offerContact ? (
							offerContact.is_default ? (
								<div className={styles.mandat_pending}>
									<span>?</span>
									{t('format.capitalize', {
										text: t('general.awaiting_mandate')
									})}
								</div>
							) : (
								<UserCard
									data={userCardData}
									theme={ThemeUserCard.SQUARE}
								/>
							)) : t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.add_contact')
						})
					}
				</span>
			</TooltipModal>
		</div>
	);

	const roleElement = (
		<div
			className={`${styles.cell_role} ${activeElement === 'role' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="role"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_role')
				})} :</span>
			)}
			{offerContact?.role ? t('format.capitalize', {
				text: t(`page.offers_create_edit.contacts.role.${offerContact.role}`)
			}) : ''}
		</div>
	);

	const mandateElement = (
		<div
			className={`${styles.cell_mandate} ${activeElement === 'mandate' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="mandate"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span>{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_mandate')
				})} :</span>
			)}
			{offerContact?.is_mandated === undefined
				? ''
				: offerContact.is_mandated
					? t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.with_mandate')
					})
					: t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.without_mandate')
					})
			}
		</div>
	);

	const mandateNumberElement = (
		<div
			className={`${styles.cell_mandate_number} ${activeElement === 'mandate_number' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="mandate_number"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_mandate_number')
				})} :</span>
			)}
			{offerContact?.mandate_number ? offerContact.mandate_number : ''}
		</div>
	);

	const refOfferElement = (
		<div
			className={`${styles.cell_ref} ${activeElement === 'ref' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="ref"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_ref')
				})} :</span>
			)}
			{offerContact?.reference ? offerContact.reference : ''}
		</div>
	);

	const receiptDateDesktop = (
		<div
			className={`${styles.cell_receipt} ${activeElement === 'receipt' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="receipt"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_receipt')
				})} :</span>
			)}
			{offerContact?.original_mail_date ? offerContact.original_mail_date : ''}
		</div>
	);

	const linkElement = (
		<div
			className={`${styles.cell_link} ${activeElement === 'link' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="link"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_link')
				})} :</span>
			)}
			{offerContact?.web_reference ? offerContact.web_reference : ''}
		</div>
	);

	const commentElement = (
		<div
			className={`${styles.cell_comment} ${activeElement === 'comment' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id="comment"
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_comment')
				})} :</span>
			)}
			{offerContact?.comment ? offerContact.comment : ''}
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			{canSelect ? <div className={styles.selection}>{checkboxElement}</div> : null}
			{contactIdentityElement}
			{roleElement}
			{mandateElement}
			{mandateNumberElement}
			{refOfferElement}
			{receiptDateDesktop}
			{linkElement}
			{commentElement}
		</div>
	);
};

export { ContactRow as default, };
