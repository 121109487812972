import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
	useState,
} from 'react';

import {
	useTranslation,
} from 'react-i18next';

// TYPES
import {
	DemandJsonPrivate,
} from '@@types/Demand';
import {
	Offer,
} from '@@types/Offer';
import {
	Lot,
} from '@@types/Lot';
import {
	ContactDemand,
} from '@@types/ContactDemand';

// DATAS
import getColumns from '@static_data/area-table.data';
import {
	getItemsContactData,
} from '@static_data/button-with-dropdown-contact.data';

// ENUMS
import {
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';
import {
	EnumImageFormatQuery,
} from '@appConf/vars.conf';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// COMPONENTS
import Button from '@components/button';
import ButtonWithDropdown from '@components/button-with-dropdown';
import Icon from '@components/icon';
import LabelStatus, {
	LabelStatusProps,
} from '@components/label-status';
import Link from '@components/link';
import Tooltip from '@components/tooltip';
import Image from '@components/image';
import TooltipModal from '@components/tooltip-modal';
import Paragraph from '@components/paragraph';
import Table from '@components/table';
import {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './offer-row.module.scss';
import {
	updateImageUrls,
} from '@modules/images';

interface OfferRowProps {
	actions?: ReactNode;
	address: string;
	areaBuilding?: string;
	areaLot?: string;
	availability?: string;
	canSelect?: boolean;
	className?: string;
	checkboxElement?: ReactNode;
	'data-testid'?: string;
	data?: Offer;
	dates?: string[];
	demandGlobalState?: DemandJsonPrivate;
	iconsList?: {
		className?: string;
		id: string;
		name: string;
		style: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
		tooltip?: string;
	}[];
	image: string;
	isDesktopResolution?: boolean;
	isSelected?: boolean;
	isPinned?: boolean;
	isViewed?: boolean;
	labels?: string[];
	mandate?: {
		type: string;
		theme: string;
	};
	price: ReactNode;
	reference?: {
		label?: string;
		url?: string;
	};
	transactionTypes?: string;
	onClickContact?: (offerId: number) => Promise<void>;
	onClickSlider?: (event: MouseEvent<HTMLElement>) => void;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickLot?: (offerId: number) => Promise<void>;
	onClickThumbtack?: (event: MouseEvent<HTMLElement>) => void;
}

const OfferRow = ({
	'data-testid': dataTestid,
	actions,
	address,
	areaBuilding,
	areaLot,
	availability,
	canSelect,
	checkboxElement,
	className,
	data,
	dates,
	iconsList,
	image,
	isSelected,
	isPinned,
	isViewed,
	labels,
	mandate,
	onClickContact,
	onClick,
	onClickLot,
	onClickThumbtack,
	price,
	reference,
	transactionTypes,
}: OfferRowProps): JSX.Element => {
	const {t} = useTranslation();
	const tooltipRef = React.useRef(null);

	const cssClasses = [
		styles.offer_row,
	];

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.can_select);
	if (isSelected) cssClasses.push(styles.selected);
	if (isPinned) cssClasses.push(styles.pinned);

	const handleOnClickThumbtack = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		onClickThumbtack ? onClickThumbtack(event) : null;
	};

	const pinnedElement = (
		<div className={`${styles.cell} ${canSelect ? styles.pin_can_select : styles.pin}`}>
			<Button
				hasBoxShadow={false}
				iconLeft='thumbtack'
				iconStyle={isPinned ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT}
				name='pin'
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
				onClick={handleOnClickThumbtack}
			/>
		</div>
	);

	const imageElement = (
		<div className={`${styles.cell} ${styles.image}`}>
			<Image
				className={styles.container}
				imagePlaceholder={
					{
						icon: 'picture',
					}
				}
				src={image ? updateImageUrls(image, EnumImageFormatQuery.TABLE_VIEW) as string || undefined : undefined}
			/>
			{isViewed ? (
				<LabelStatus
					className={styles.label_status}
					context='female'
					text={'viewed'}
				/>
			) : null}
		</div>
	);

	const mandateElement = (
		mandate?.type ? (
			<LabelStatus
				className={`${styles.label_status} ${styles.ellipsis}`}
				data-testid={`${dataTestid}-mandate-type`}
				hasBackground={false}
				text={mandate.type}
				theme={mandate.theme as LabelStatusProps['theme']}
			/>
		) : null
	);

	const informationsElement = (
		<div className={`${styles.cell} ${styles.informations}`}>
			{dates?.length ? (
				<div className={styles.dates}>
					{dates.map((date: string, index: number) => {
						return (
							<div
								className={styles.date_txt}
								key={index}
							>
								{date}
							</div>
						);
					})}
				</div>
			) : null}
			<div className={styles.info}>
				<Link
					className={styles.ref}
					href={reference.url}
					target={EnumTargetLink.BLANK}
				>
					{reference.label}
				</Link>{' - '}
				<span className={styles.loc}>{transactionTypes}{mandateElement ? ' - ' : null}</span>
				{mandateElement}
			</div>
			<div className={styles.ellipsis}>{address}</div>
		</div>
	);

	const iconsListElement = (
		<div className={`${styles.cell} ${styles.iconsList}`}>
			{iconsList?.length ? (
				iconsList.map((icon, key: number) => {
					return (
						<Tooltip
							className={styles.tooltip}
							content={icon.tooltip}
							id={icon.id}
							key={key}
						>
							<Icon
								data-testid={`${dataTestid}-${icon.id}`}
								fontStyle={icon.style}
								name={icon.name}
							/>
						</Tooltip>
					);
				}) ) : null}
		</div>
	);

	const priceElement = (
		<div className={`${styles.cell} ${styles.price}`}>
			{price}
		</div>
	);

	const areaElement = (
		<div className={`${styles.cell} ${styles.area}`}>
			<span className={styles.ellipsis}>{areaBuilding}</span>
		</div>
	);

	const availabilityElement = (
		<div className={`${styles.cell} ${styles.availability}`}>
			<span className={styles.ellipsis}>{availability}</span>
		</div>
	);

	const filteredLabels = labels.filter(label => label !== 'viewed' && label !== 'pinned');

	const generateLabelElements = (labels: string[], limit: number | null = null) => {
		return (
			<ul className={styles.labels_list}>
				{labels.slice(0, limit || labels.length).map((label: string, index: number) => (
					<li
						className={styles.labels_item}
						key={index}
					>
						<LabelStatus
							context='female'
							text={label}
						/>
					</li>
				))}
				{limit && labels.length > limit && (
					<li
						className={styles.labels_item}
						key={limit}
					>
						<TooltipModal
							className={styles.tooltip_modal}
							clickable={true}
							htmlElement={generateLabelElements(filteredLabels)}
							id={data.id.toString()}
							openOnClick={false}
							place={EnumTooltipPosition.LEFT}
						>
							<div className={styles.more}>+{labels.length - limit}</div>
						</TooltipModal>
					</li>
				)}
			</ul>

		);
	};

	const labelElement = (
		<div className={`${styles.cell} ${styles.labels}`}>
			{generateLabelElements(filteredLabels, 2)}
		</div>
	);

	const areaTableColumnsConf = getColumns(data);

	const areaTableDataConf = [
	] as object[];
	data?.lots?.collection?.forEach((lot: Lot) => {
		let lotAvailabilityDateState = null;

		switch (lot?.availability?.state) {
			case 'under_option':
			case 'under_promise':
				lotAvailabilityDateState = (
					<LabelStatus
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.WARNING}
					/>
				);
				break;
			case 'suspended':
				lotAvailabilityDateState = (
					<LabelStatus
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.ERROR}
					/>
				);
				break;
			case 'immediate_availability':
				lotAvailabilityDateState = (
					<LabelStatus
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumTheme.ELEVATION}
					/>
				);
		}
		const displayedNatures = lot?.natures?.length ? (
			lot.natures.map((nature: string) => {
				return t('format.capitalize', {
					text: t(`references.natures.${nature}`)
				});
			}).join(' - ')
		) : null;

		const lotData = {
			'building': lot?.building ? lot?.building : '-',
			'lot': lot?.reference ? lot?.reference : '-',
			'floor': lot?.floor ? lot?.floor : '-',
			'nature': displayedNatures ? displayedNatures : '-',
			'area': lot?.area?.value ? t('format.area', {
				val: lot?.area?.value
			}) : '-',
			'rental_price': lot?.rentalPrice?.price?.amount ? lot.rentalPrice.price.amount : '-',
			'sale_price': lot?.salePrice?.price?.amount ? lot?.salePrice.price.amount : '-',
			'availability': lotAvailabilityDateState || lot?.availability?.date_txt || lot?.availability?.date,
		};
		areaTableDataConf.push(lotData);
	});

	const title = (
		<div className={styles.tooltip_lots}>
			<span className={styles.ref_title}>{t('format.capitalize', {
				text: t('page.demand.offer.lots_title', {
					'%ref%': data.id.toString(),
				})
			})}</span>
			<span className={styles.area_title}>{areaBuilding}</span><span className={styles.lot_title}>{areaLot}</span>
		</div>
	);

	const areaTableElement  = (
		<Paragraph>
			{title}
			<Table
				columns={areaTableColumnsConf}
				data={areaTableDataConf}
				identifier={`table_offer_${data?.id}`}
				options={{
					initialSort: [
						{
							'id': 'building',
							'desc': false
						}
					],
					sort: [
						'building',
						'lot',
						'floor'
					],
					pagination: {
						nbItemsPerPage: 5,
					}
				}}
				theme='column'
			/>
		</Paragraph>
	);

	const [
		isLoading,
		setIsLoading
	] = useState(false);

	const [
		isLoadingLots,
		setIsLoadingLots
	] = useState(false);

	const handleOnClickContact = () => {
		setIsLoading(true);
		onClickContact(data?.id).finally(() => {
			setIsLoading(false);
		});
	};

	const handleOnClickLot = () => {
		setIsLoadingLots(true);
		onClickLot(data?.id).finally(() => {
			setIsLoadingLots(false);
		});
	};

	const moreInfoElement = (
		<div className={`${styles.cell} ${styles.more_info}`}>
			<ButtonWithDropdown
				disabled={isLoading}
				hasBoxShadow={false}
				iconLeft='address-book'
				iconStyle={EnumFontStyle.LIGHT}
				isOpenAfterClick={true}
				items={data?.contacts?.collection?.length ? getItemsContactData(data.contacts.collection as ContactDemand[], ThemeUserCard.SQUARE) : undefined}
				loader={isLoading}
				name={`mandated_agents_${data.id}`}
				shortDropdown={true}
				size={EnumButtonSize.SMALL}
				style={{
					right: '100%',
					top: '0%',
				}}
				theme={EnumTheme.NAKED}
				title={{
					label: t('format.capitalize', {
						text: t('general.contact_title')
					})
				}}
				tooltipText={t('format.capitalize', {
					text: t('general.action.mandated_agents'),
				})}
				onClick={handleOnClickContact}
			/>
			<div ref={tooltipRef}>
				<TooltipModal
					className={`${styles.tooltip_modal}`}
					clickable={true}
					htmlElement={areaTableElement}
					id={`${data.id}lots`}
					openOnClick={true}
					place={EnumTooltipPosition.LEFT_START}
				>
					<Button
						disabled={isLoadingLots}
						hasBoxShadow={false}
						iconLeft='area'
						iconStyle={EnumFontStyle.LIGHT}
						loader={isLoadingLots}
						name="area"
						theme={EnumTheme.NAKED}
						tooltipText={t('format.capitalize', {
							text: t('general.action.surface_area_table'),
						})}
						onClick={handleOnClickLot}
					/>
				</TooltipModal>
			</div>
		</div>
	);

	const actionsElement = (
		<div className={`${styles.cell} ${styles.actions}`}>
			{actions}
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
			onClick={onClick}
		>
			{canSelect ? (
				<div className={styles.selection}>
					{checkboxElement}
				</div>
			) : null}
			{pinnedElement}
			{imageElement}
			{informationsElement}
			{iconsListElement}
			{priceElement}
			{areaElement}
			{availabilityElement}
			{labelElement}
			{moreInfoElement}
			{actionsElement}
		</div>
	);
};

export {OfferRow as default,};
