import React, {
	useState,
} from 'react';
import {
	useTranslation, withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// CONFIG
import columnTableConf from './config/columnTable.config';

// TYPES
import {
	ContactOffer,
} from '@@types/ContactOffer';

// ENUMS
import {
	EnumModalSize,
} from '@enums/modal.enum';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import ContactRow from './contact-row';
import Modal from '@components/modal';
import Table from '@components/table';

// STYLES
import styles from './contacts.module.scss';
import ModalOffersContact from '@layouts/Modals/ModalOffersContact';

const Contacts = () => {
	const { t } = useTranslation();

	const {
		isDesktopResolution,
		offerEnumsState,
		offerState,
		offerStateContacts,
		setOfferStateContacts,
		setOfferState,
	} : PageOfferContextProps = useOutletContext();

	const [
		contactsIds,
		setContactsIds
	] = useState([
	] as number[]);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const [
		selectedRowId,
		setSelectedRowId
	] = useState(undefined as number);

	console.log('contactsIds', contactsIds);
	const dataTable = offerStateContacts?.contacts?.collection ? [
		...offerStateContacts.contacts.collection
	] : [
	];

	dataTable.push({
	} as ContactOffer);

	const modalElement = (
		<Modal
			hasHeader={true}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenModal}
			size={EnumModalSize.DEFAULT}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.modal.title')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<ModalOffersContact
				offerEnumsState={offerEnumsState}
				offerStateContacts={offerStateContacts}
				setIsOpenModal={setIsOpenModal}
				setOfferStateContacts={setOfferStateContacts}
			/>
		</Modal>
	);

	const inputsContacts = (
		<Table
			className={styles.table}
			columns={columnTableConf}
			data={dataTable}
			identifier='table_contacts'
			options={{
				canSelect: true,
				hasHeader: isDesktopResolution,
				pagination: {
					nbItemsPerPage: 10,
					onChange: (event) => {
						console.log(event);
						// handleOnChangePagination(event);
					}
				},
			}}
			// reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				const handleRowClick = (rowId: number) => {
					setSelectedRowId(rowId); // Toggle selection
				};
				const filteredContacts = dataTable?.filter((contact: ContactOffer) => contact.id === props.original.id);

				const currentContact = filteredContacts.length > 0 && filteredContacts[0].id ? new ContactOffer(filteredContacts[0]) : null;

				return (
					<ContactRow
						{...props}
						canSelect={true}
						currentOfferId={offerState.id}
						isDesktopResolution={isDesktopResolution}
						offerContact={currentContact}
						offerStateContacts={offerStateContacts}
						rowId={props.index}
						selectedRowId={selectedRowId}
						setIsOpenModal={setIsOpenModal}
						setOfferStateContacts={setOfferStateContacts}
						onClickRow={handleRowClick}
					/>
				);

			}}
			setComponentSelectedIds={setContactsIds}
			// setReinitSelection={setReinitSelection}
			theme='column'
		/>
	);

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 3/8`
				})}
			</div>
			<div className={styles.legend}>{t('general.form.input.required')}</div>
			{/*--------------- Quels sont les contacts pour votre offre ? ---------------*/}
			<div className={styles.title}>{t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.title')
			})}</div>

			<div className={styles.title}>{t('page.offers_create_edit.contacts.mandate_contact_number')} ({offerStateContacts?.contacts?.collection?.length || '0'})</div>
			<ValidationForm
				inputs={inputsContacts}
				isDesktopResolution={isDesktopResolution}
				isValid={offerState ? true : false}
				offerState={offerState}
				setOfferState={setOfferState}
			/>
			{modalElement}
		</div>
	);
};

export default withTranslation()(Contacts);
