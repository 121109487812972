import React, {
	useEffect,
	useState,
	ElementType,
	ReactNode,
	Children,
	isValidElement,
} from 'react';
import {
	useDropzone,
	DropzoneOptions as ReactDropzoneOptions,
	type DropEvent as DropzoneDropEvent,
	type FileRejection as DropzoneFileRejection,
} from 'react-dropzone';

import {
	DropzoneChildDefault,
	DropzoneChildOver,
	DropzoneChildProcessing,
} from './dropzone-child';

import styles from './dropzone.module.scss';

type DropzoneOptions = ReactDropzoneOptions;

type DropzoneProps = {
	'data-testid'?: string;
	children?: ReactNode;
	isProcessing?: boolean;
	options?: DropzoneOptions;
	onDrop?: <T extends File>(
		acceptedFiles: T[],
		fileRejections: DropzoneFileRejection[],
		event: DropzoneDropEvent
	) => void;
	onDropAccepted?: <T extends File>(files: T[], event: DropzoneDropEvent) => void;
	onDropRejected?: (fileRejections: DropzoneFileRejection[], event: DropzoneDropEvent) => void;
};

const Dropzone = ({
	children,
	'data-testid': dataTestid,
	isProcessing = false,
	onDrop,
	onDropAccepted,
	onDropRejected,
	options,
	...otherProps
}: DropzoneProps): JSX.Element => {

	const [
		state,
		setState
	] = useState(isProcessing ? 'processing' : 'default');

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isFileDialogActive
	} = useDropzone({
		...options,
		onDrop,
		onDropAccepted,
		onDropRejected
	});

	useEffect(() => {
		if (isProcessing) {
			setState('processing');
		} else if (isDragActive || isFileDialogActive) {
			setState('active');
		} else {
			setState('default');
		}
	}, [
		isProcessing,
		isDragActive,
		isFileDialogActive
	]);

	const classList: string[] = [
		styles.dropzone
	];

	function getChildrenByType(children: ReactNode, type: ElementType) {
		const filteredChildren = Children.toArray(children).filter(child => {
			return isValidElement(child) && child.type === type;
		});
		return filteredChildren;
	}

	let result = getChildrenByType(children, DropzoneChildDefault);
	let resultElementProcessing = null;
	let resultElement = null;

	// KEEP THIS ORDER, IT'S IMPORTANT HERE
	switch (state) {
		case 'processing':
			resultElementProcessing = getChildrenByType(children, DropzoneChildProcessing);
			result = null;
			break;
		case 'active':
			resultElementProcessing = null;
			result = getChildrenByType(children, DropzoneChildOver);
			break;
		default:
			resultElementProcessing = null;
			result = getChildrenByType(children, DropzoneChildDefault);
			break;
	}

	resultElement = (
		<div
			{...otherProps}
			{...getRootProps({
				className: classList.filter(n => n).join(' ')
			})}
			data-testid={`${dataTestid}-wrapper-${state}`}
		>
			<input
				{...getInputProps()}
				data-testid={`${dataTestid}-input`}
			/>
			{result}
		</div>
	);

	return (
		<>
			{resultElementProcessing}
			{resultElement}
		</>
	);
};

export {
	Dropzone,
	DropzoneChildDefault,
	DropzoneChildOver,
	DropzoneChildProcessing,
	DropzoneDropEvent,
	DropzoneFileRejection,
	DropzoneOptions,
	DropzoneProps,
};
